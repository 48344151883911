import { zodResolver } from '@hookform/resolvers/zod'
import { type SubmitOptions, useSubmit } from '@remix-run/react'
import { MapPin } from 'lucide-react'
import React from 'react'
import { type FieldErrors, useForm } from 'react-hook-form'
import { Button } from '#app/components/ui/button.tsx'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '#app/components/ui/dialog.tsx'
import { Form, FormField, FormItem } from '#app/components/ui/form.tsx'
import { Input } from '#app/components/ui/input.tsx'
import { Label } from '#app/components/ui/label.tsx'
import { ItineraryVisbilitySelectFormField } from '#app/components/visibility-select.tsx'
import { cn } from '#app/utils/misc.tsx'
import {
  ItineraryDocumentCreateSchema,
  type ItineraryDocumentCreate,
} from '#server/itinerary/itinerary.ts'
import { ItineraryVisibility } from '#server/itinerary/models.ts'

const useCreateItineraryOnSubmit = (submitOptions: SubmitOptions = {}) => {
  const submit = useSubmit()

  return React.useCallback(
    (data: ItineraryDocumentCreate) => {
      submit(
        { data },
        {
          method: 'POST',
          action: '/itinerary/create',
          encType: 'application/json',
          ...submitOptions,
        },
      )
    },
    [submitOptions, submit],
  )
}

const ItineraryCreator = Dialog
ItineraryCreator.displayName = 'ItineraryCreator'

interface ItineraryCreatorTriggerContentProps {
  label?: string
}
const ItineraryCreatorTriggerContent = ({
  label,
}: ItineraryCreatorTriggerContentProps) => (
  <>
    <MapPin className="mr-2 h-4 w-4" />
    <span>{label != null ? label : 'Create an Itinerary'}</span>
  </>
)

const ItineraryCreateButton = React.forwardRef<
  React.ElementRef<typeof DialogTrigger>,
  Omit<React.ComponentPropsWithoutRef<typeof DialogTrigger>, 'asChild'>
>(({ ...props }, ref) => (
  <DialogTrigger ref={ref} {...props} asChild>
    <Button variant="outline">
      <ItineraryCreatorTriggerContent />
    </Button>
  </DialogTrigger>
))
ItineraryCreateButton.displayName = 'ItineraryCreateButton'

interface ItineraryCreateContentProps {
  className?: string
  userId: string
  onSubmit?: (data: ItineraryDocumentCreate) => void
}
const ItineraryCreateContent = ({
  userId,
  onSubmit,
  className,
}: ItineraryCreateContentProps) => {
  const form = useForm<ItineraryDocumentCreate>({
    resolver: zodResolver(ItineraryDocumentCreateSchema),
    defaultValues: {
      createdBy: userId,
      header: {
        title: 'My Itinerary',
      },
      visibility: ItineraryVisibility.Private,
    },
  })

  const handleSubmit = React.useCallback(
    (data: ItineraryDocumentCreate) => {
      onSubmit && onSubmit(data)
    },
    [onSubmit],
  )

  const handleInvalid = React.useCallback(
    (err: FieldErrors<ItineraryDocumentCreate>) => {
      console.error(err)
    },
    [],
  )

  return (
    <DialogContent
      className={cn('sm:max-w-[425px]', className)}
      aria-describedby={undefined}
    >
      <DialogHeader>
        <DialogTitle>Create a new itinerary</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit, handleInvalid)}>
          <div className="grid gap-4 py-4">
            <FormField
              control={form.control}
              name="createdBy"
              render={({ field }) => (
                <FormItem className="sr-only">
                  <Input {...field} hidden />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="header.title"
              render={({ field }) => (
                <FormItem className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="header.title" className="text-right">
                    Itinerary title
                  </Label>
                  <Input id="header.title" className="col-span-3" {...field} />
                </FormItem>
              )}
            />
            <ItineraryVisbilitySelectFormField
              name="visibility"
              control={form.control}
            />
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button type="submit">Create Itinerary</Button>
            </DialogClose>
          </DialogFooter>
        </form>
      </Form>
    </DialogContent>
  )
}

export {
  useCreateItineraryOnSubmit,
  ItineraryCreator,
  ItineraryCreatorTriggerContent,
  ItineraryCreateButton,
  ItineraryCreateContent,
}
