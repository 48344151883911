import {
  json,
  type MetaFunction,
  type LoaderFunctionArgs,
} from '@remix-run/node'
import { useFetcher, useLoaderData } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useCallback, useMemo, useState } from 'react'
import { CardPreviewTitle, CardPreviewImage } from '#app/components/card'
import { ItineraryDocumentPreview } from '#app/components/card/itinerary-document-preview.tsx'
import { CardType } from '#app/components/card/types.ts'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '#app/components/ui/alert-dialog.tsx'
import { Dialog, DialogTrigger } from '#app/components/ui/dialog.tsx'
import { getItineraries } from '#server/itinerary/itinerary.server.ts'
import {
  type ItineraryDocumentStateWithId,
  ItineraryDocumentStateWithIdSchema,
} from '#server/itinerary/itinerary.ts'
import {
  ItineraryCreateContent,
  useCreateItineraryOnSubmit,
} from './itinerary-creator.component.tsx'

export const meta: MetaFunction = () => [{ title: 'Itinerary | Husthere' }]

export async function loader({ context }: LoaderFunctionArgs) {
  const userId = context.user?.id ?? context.anonymousUser.id
  const itineraries = await getItineraries(userId)

  return json({
    userId,
    itineraries: itineraries ?? [],
  })
}

export default function Index() {
  const { userId, itineraries } = useLoaderData<typeof loader>()

  const [itineraryToDelete, setItineraryToDelete] =
    useState<ItineraryDocumentStateWithId | null>(null)

  const parsedItineraries = useMemo(() => {
    return itineraries.reduce<ItineraryDocumentStateWithId[]>(
      (acc, itinerary) => {
        const result = ItineraryDocumentStateWithIdSchema.safeParse(itinerary)
        if (result.success) {
          return [...acc, result.data]
        } else {
          Sentry.captureMessage(result.error.message, 'error')
          return acc
        }
      },
      [],
    )
  }, [itineraries])

  const createItinerary = useCreateItineraryOnSubmit()

  const itinerariesToDisplay = useMemo(() => {
    return parsedItineraries.map(itinerary => {
      return {
        ...itinerary,
        numDays: itinerary?.itinerary.days?.length ?? 0,
      }
    })
  }, [parsedItineraries])

  const handleShowDeleteItineraryConfirmation = useCallback(
    (itinerary: ItineraryDocumentStateWithId) => {
      setItineraryToDelete(itinerary)
    },
    [],
  )

  const deleteFetcher = useFetcher()
  const handleDeleteItinerary = useCallback(
    (itinerary: ItineraryDocumentStateWithId) => {
      deleteFetcher.submit(
        {
          itineraryId: itinerary._id,
        },
        {
          method: 'delete',
          action: 'delete',
        },
      )
    },
    [deleteFetcher],
  )

  return (
    <>
      <main className="container flex flex-col">
        <header className="mb-4 h-fit py-2">
          <h1 className="text-4xl font-semibold leading-6 -tracking-tight">
            Itineraries
          </h1>
        </header>
        <section className="grid h-full w-full flex-1 grid-flow-row-dense auto-rows-[340px] grid-cols-1 gap-3 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
          {itinerariesToDisplay.map(itinerary => (
            <ItineraryDocumentPreview
              key={itinerary._id}
              id={itinerary._id ?? ''}
              title={itinerary.header?.title ?? 'Untitled'}
              numDays={itinerary.numDays}
              thumbnailPhotos={itinerary.metadata?.thumbnail}
              onDelete={() => handleShowDeleteItineraryConfirmation(itinerary)}
            />
          ))}
          <Dialog>
            <div className="flex flex-col">
              <DialogTrigger className="mb-4 flex-1">
                <CardPreviewImage
                  className="text-husthere h-full bg-[#6161f140] bg-opacity-40"
                  type={CardType.Add}
                />
              </DialogTrigger>
              <div className="mb-6 flex justify-center">
                <CardPreviewTitle
                  className="text-husthere"
                  text="New Itinerary"
                />
              </div>
            </div>

            <ItineraryCreateContent
              userId={userId}
              onSubmit={createItinerary}
            />
          </Dialog>
        </section>
      </main>

      {itineraryToDelete != null && (
        <AlertDialog
          open
          onOpenChange={isOpen => !isOpen && setItineraryToDelete(null)}
        >
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete your
                itinerary and remove your data from our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                className="bg-red-500 text-white"
                onClick={() => handleDeleteItinerary(itineraryToDelete)}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}
